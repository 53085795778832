import React from 'react';
import { Route as ReactDOMRoute, RouteProps as ReactDOMRouteProps, Redirect } from 'react-router-dom';
import Layout from '../Layout';

import { useAuth } from '../hooks/auth';
interface RouteProps extends ReactDOMRouteProps {
  isPrivate?: boolean;
  component: React.ComponentType;
}

const Route: React.FC<RouteProps> = ({ isPrivate = false, component: Component, location, ...rest }) => {
  const { credentials } = useAuth();

  if (!credentials && isPrivate) {
    return <Redirect to="/login" />;
  }

  if (credentials && !isPrivate && location?.pathname !== '/404') {
    return <Redirect to="/" />;
  }

  return <ReactDOMRoute {...rest} render={() => (credentials ? <Layout>{<Component />}</Layout> : <Component />)} />;
};

export default Route;
