import React from 'react';

import { Result, Button } from 'antd';
import { useHistory, useLocation } from 'react-router-dom';

const Page404: React.FC = () => {
  const history = useHistory();
  const location = useLocation<any>();
  const { message }: { message: string } = location.state;

  return (
    <Result
      status="404"
      title="404"
      subTitle={message || 'Sinto muito, a página que você quer visitar não existe.'}
      extra={
        <Button type="primary" onClick={() => history.push('/')}>
          Voltar para Home
        </Button>
      }
    />
  );
};

export default Page404;
