import React, { createContext, useState, useCallback, useContext, useEffect } from 'react';

import { responseInterceptor } from '../services/api';
import { message, notification } from 'antd';
import { useHistory } from 'react-router-dom';
import { securityKey, customerId, customerKey, distributor, pointOfSale } from '../services/env';

interface CredentialsType {
  customerId: string;
  customerKey: string;
}

interface AuthContextData {
  signIn(password: string): void;
  signOut(): void;
  credentials?: CredentialsType;
}

const appName = 'CheckoutPDV@';
const AuthContext = createContext<AuthContextData>({} as AuthContextData);

const AuthProvider: React.FC = ({ children }) => {
  const [credentials, setCredentials] = useState<CredentialsType>();
  const history = useHistory();

  const signOut = useCallback(async () => {
    localStorage.removeItem(`${appName}:logged`);
    setCredentials(undefined);
  }, []);

  useEffect(() => {
    if (!customerId || !customerKey || !pointOfSale || !securityKey || !distributor) {
      history.replace('/404', { message: 'Aplicação não autorizada, fale com o administrador' });
    }

    const logged = localStorage.getItem(`${appName}:logged`);
    if (logged && customerId && customerKey) {
      setCredentials({ customerId, customerKey });
    }
  }, [history]);

  useEffect(() => {
    // load interceptor response
    responseInterceptor(signOut, notification);
  }, [signOut]);

  const signIn = useCallback(
    (password) => {
      if (!customerId || !customerKey) {
        message.error('Aplicação não autorizada, fale com o administrador');
        history.replace('/404');
      } else {
        if (!securityKey || password !== securityKey) {
          notification.error({
            message: 'Atenção',
            description: 'Dados de acesso inválido. Entre em contato com o Administrador',
            placement: 'bottomRight',
          });
        } else {
          localStorage.setItem(`${appName}:logged`, JSON.stringify(true));
          setCredentials({ customerId, customerKey });
        }
      }
    },
    [history]
  );

  return (
    <AuthContext.Provider
      value={{
        signIn,
        signOut,
        credentials,
      }}>
      {children}
    </AuthContext.Provider>
  );
};

function useAuth(): AuthContextData {
  const context = useContext(AuthContext);

  if (!context) {
    throw new Error('useAuth must be used within an AuthProvider');
  }
  return context;
}

export { AuthProvider, useAuth };
