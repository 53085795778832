import React from 'react';
import md5 from 'md5';

import { Form, Input, Button } from 'antd';

import { Container } from './styles';
import { useAuth } from '../../hooks/auth';
import { LoginOutlined } from '@ant-design/icons';

const Login: React.FC = () => {
  const { signIn } = useAuth();

  const onFinish = (values: any) => {
    signIn(md5(values.password));
  };

  const onFinishFailed = (errorInfo: any) => {
    console.log('Failed:', errorInfo);
  };

  return (
    <Container>
      <LoginOutlined style={{ fontSize: 66, marginBottom: 5, color: '#1890ff' }} />
      <h1 style={{ fontSize: 30, marginBottom: 10, color: '#1890ff' }}>Ponto de Venda</h1>
      <Form layout="vertical" name="basic" onFinish={onFinish} onFinishFailed={onFinishFailed}>
        <Form.Item label="Digite sua senha de acesso" name="password" rules={[{ required: true, message: 'Por favor, digite a senha para entrar!' }]}>
          <Input.Password size="large" />
        </Form.Item>

        <Form.Item>
          <Button shape="round" icon={<LoginOutlined />} style={{ width: '100%' }} type="primary" htmlType="submit">
            Entrar
          </Button>
        </Form.Item>
      </Form>
    </Container>
  );
};

export default Login;
