export default function removeAllSpaces(str: string): string {
  return str.replace(/[^0-9]+/g, '');
}

export function onlyNumbers(str: string): string {
  return str.replace(/[^0-9]+/g, '');
}

export function capitalizeFirstLetter(string: string): string {
  return string.charAt(0).toUpperCase() + string.slice(1);
}

export function formatNumberTicket(string: string): string {
  return string.replace(/(.{3})/g, '$1.').substr(0, 7);
}

export function formatCelular(string: string): string {
  const ddd = onlyNumbers(string).substr(0, 2);
  const cel1 = onlyNumbers(string).substr(2, 5);
  const cel2 = onlyNumbers(string).substr(7);
  return `(${ddd}) ${cel1}-${cel2}`;
}
export function formatCep(string: string): string {
  const cep1 = onlyNumbers(string).substr(0, 5);
  const cep2 = onlyNumbers(string).substr(5, 3);
  return `${cep1}-${cep2}`;
}

export function toCurrency(value: string | number): string {
  const string = String(value);
  return `R$ ${String(parseFloat(string).toFixed(2)).replace('.', ',')}`;
}

export function hideEmail(value: string): string {
  if (!value.includes('@')) {
    return value;
  }
  const array = value.split('@');
  const prefix = `${''.padStart(array[0].length, '*')}`;
  const sufix = `@${array[1]}`;
  const email =
    (array[0].length > 2 ? prefix.substr(2) + array[0].substr(-2) : prefix) +
    sufix;
  return email;
}

export function hideCelular(value: string): string {
  const number = `${''.padStart(7, '*') + value.substr(-4)}`;
  return number;
}
