import styled from 'styled-components';

export const Container = styled.div`
  display: flex;
  flex-direction: column;
  height: 90vh;
  max-width: 500px;
  justify-content: center;
  align-items: center;
  margin: 0 auto;

  @media print {
    .no-print,
    .no-print * {
      display: none !important;
    }
  }
`;
