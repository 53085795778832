import React from 'react';
import { Layout } from 'antd';

const { Content } = Layout;

const LayoutDefault: React.FC = ({ children }) => {
  return (
    <Layout>
      <Content
        style={{
          padding: 24,
          margin: 0,
          height: '100vh',
        }}>
        {children}
      </Content>
    </Layout>
  );
};

export default LayoutDefault;
