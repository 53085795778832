import { NotificationApi } from "antd/lib/notification";
import axios from "axios";
import { baseURL } from "./env";
import { customerId, customerKey } from "../services/env";

const api = axios.create({
  baseURL,
  headers: {
    customerid: customerId,
    customerkey: customerKey,
  },
});

// INTERCEPTORS

export const responseInterceptor = (
  signOut: () => void,
  notification: NotificationApi
): any => {
  function newToast(message: string, option?: any) {
    notification.error({
      message: "Atenção",
      description: message,
      placement: "bottomRight",
    });
  }

  api.interceptors.response.use(
    (response) => {
      return response;
    },

    (error) => {
      if (!error.response || !error.response.data) {
        newToast("Algo inesperado aconteceu, tente novamente em instantes.");

        return error;
      }
      switch (error.response.status) {
        case 400:
        case 412:
          newToast(error.response.data.mensagem || "Requisição inválida");
          return error;
        case 401:
          newToast(error.response.data.mensagem || "Não Autorizado");
          signOut();
          return error;
        case 404:
          newToast("Recurso não disponível.");
          return error;
        case 403:
          newToast("Sessão Expirada. Faça login novamente.");
          signOut();
          return error;
        case 500:
          newToast("Algo inesperado aconteceu, tente novamente em instantes.");
          return error;
        default:
          newToast(
            error.response.data.mensagem ||
              "Algo inesperado aconteceu, tente novamente em instantes."
          );
          return error;
      }
    }
  );
};

export default api;
