import React from 'react';
import { Switch } from 'react-router-dom';

import Route from './Route';
import GlobalStyle from '../styles/global';

import Login from '../pages/Login';
import Page404 from '../pages/Page404';
import Checkout from '../pages/Checkout';

const Routes: React.FC = () => {
  return (
    <>
      <Switch>
        <Route path="/checkout" component={Checkout} isPrivate />
        <Route path="/login" component={Login} />
        <Route path="/404" component={Page404} />
        <Route path="/" exact component={Checkout} isPrivate />
        <Route path="*" component={Page404} isPrivate />
      </Switch>
      <GlobalStyle />
    </>
  );
};

export default Routes;
