/* eslint-disable no-template-curly-in-string */
import React, { useCallback, useMemo, useState } from "react";
import {
  Form,
  Button,
  InputNumber,
  Select,
  Popconfirm,
  Modal,
  notification,
  Descriptions,
} from "antd";
import { distributor, pointOfSale, product } from "../../services/env";
import {
  CheckOutlined,
  ExclamationCircleOutlined,
  LeftOutlined,
  LogoutOutlined,
  ShopFilled,
} from "@ant-design/icons";
import { useAuth } from "../../hooks/auth";
import { Container } from "./styles";
import { MaskedInput } from "antd-mask-input";
import { isValidCpf } from "@brazilian-utils/is-valid-cpf";
import { formatNumberTicket, onlyNumbers } from "../../utils/StringHelper";
import api from "../../services/api";

const validateMessages = {
  required: "${label} é obrigatório!",
  types: {
    number: "${label} não é um número válido!",
    email: "${label} não é um e-mail válido!",
  },
  senhaAtual: {
    pattern: "${label} a senha dever conter 4 números!",
  },
};

const validateCPF = (_: any, value: string): Promise<string> => {
  const cpf = onlyNumbers(value || "");
  if (cpf && cpf.length < 11) {
    return Promise.reject("CPF inválido");
  }
  if (cpf && !isValidCpf(cpf)) {
    return Promise.reject("CPF informado não é válido");
  }
  return Promise.resolve("");
};

const validatePhone = (_: any, value: string): Promise<string> => {
  const phone = onlyNumbers(value || "");
  if (phone && phone.length < 11) {
    return Promise.reject("Telefone inválido");
  }
  return Promise.resolve("");
};

const validateCEP = (_: any, value: string): Promise<string> => {
  const cep = onlyNumbers(value || "");
  if (cep && cep.length < 8) {
    return Promise.reject("CEP inválido");
  }
  return Promise.resolve("");
};

const { Option } = Select;

const { confirm } = Modal;

interface CupomType {
  numero: number;
  dezenas: string[];
}

interface SuccessType {
  autenticacao: string;
  protocolo: number;
  dataConfirmacao: string;
  texto_sucesso: string | null;
  cupons: CupomType[];
  mensagem: string;
}

const Checkout: React.FC = () => {
  const { signOut } = useAuth();
  const [form] = Form.useForm();

  const [pdv, setPdv] = useState<string>();
  const [loading, setLoading] = useState(false);
  const [showTicket, setShowTicket] = useState(false);
  const [ticket, setTicket] = useState<SuccessType | undefined>();

  const pdvs = useMemo(() => {
    return (pointOfSale && pointOfSale.split(",")) || [];
  }, []);

  const handleConfirm = useCallback(
    async (protocolo: number, aprovado: boolean) => {
      const response = (await api.post(
        "/servicos/vendas/titulos/confirmaAtendimento",
        { aprovado, protocolo }
      )) as { data: SuccessType };
      form.resetFields();
      if (!aprovado && response) {
        notification.info({
          message: "Atenção !",
          description: response.data.mensagem,
          placement: "bottomRight",
        });
      }

      if (aprovado && response && response.data) {
        setShowTicket(true);
        setTicket(response.data);
      } else if (aprovado) {
        await api.post("/servicos/vendas/titulos/confirmaAtendimento", {
          aprovado: false,
          protocolo,
        });
      }
    },
    [form]
  );

  const handleSubmit = useCallback(
    async (values: any) => {
      setLoading(true);

      const data = {
        codProduto: product,
        quantidade: values.qty,
        pessoa: {
          cpf: values.cpf,
          celular: values.telefone,
          endereco: {
            cep: values.cep,
          },
        },
        vendedor: {
          pdv: pdv,
          distribuidor: distributor,
        },
      };
      const response = (await api.post(
        "/servicos/vendas/titulos/registraAtendimento",
        data
      )) as { data: SuccessType };
      if (response && response.data) {
        confirm({
          title: "Confirme a compra",
          icon: <ExclamationCircleOutlined />,
          content: `Clique em COMPRAR para confimar a compra de ${values.qty} item ?`,
          onOk: () => handleConfirm(response.data.protocolo, true),
          onCancel: () => handleConfirm(response.data.protocolo, false),
          okText: "COMPRAR",
          cancelText: "CANCELAR",
        });
      }

      setLoading(false);
    },
    [handleConfirm, pdv]
  );

  const handleSelectPdv = useCallback((value: string) => {
    setPdv(value);
  }, []);

  const handleBack = useCallback(() => {
    setTicket(undefined);
    setShowTicket(false);
  }, []);

  return (
    <Container>
      <ShopFilled style={{ fontSize: 66, marginBottom: 5, color: "#1890ff" }} />
      <h1 style={{ fontSize: 30, marginBottom: 10, color: "#1890ff" }}>
        Vendas {distributor}
      </h1>
      <Form
        hidden={showTicket}
        form={form}
        layout="vertical"
        className="ant-row no-print"
        onFinish={handleSubmit}
        validateMessages={validateMessages}
        initialValues={{ qty: 1, pdv }}
      >
        <Form.Item
          className="ant-col-24"
          label="Ponto de Venda"
          name="pdv"
          rules={[{ required: true }]}
        >
          <Select onSelect={handleSelectPdv}>
            {pdvs &&
              pdvs.map((pdv) => (
                <Option key={pdv} value={pdv}>
                  {pdv}
                </Option>
              ))}
          </Select>
        </Form.Item>
        <Form.Item
          className="ant-col-24"
          label="Quantidade"
          name="qty"
          rules={[{ required: true }]}
        >
          <InputNumber min={1} size="large" />
        </Form.Item>
        <Form.Item
          className="ant-col-24"
          label="CPF"
          name="cpf"
          rules={[{ required: true }, { validator: validateCPF }]}
        >
          <MaskedInput mask="111.111.111-11" />
        </Form.Item>

        <Form.Item
          className="ant-col-12"
          name="cep"
          label="CEP"
          rules={[{ required: true }, { validator: validateCEP }]}
        >
          <MaskedInput mask="11111-111" />
        </Form.Item>

        <Form.Item
          className="ant-col-12"
          name="telefone"
          label="Nº Telefone com DDD"
          rules={[{ required: true }, { validator: validatePhone }]}
        >
          <MaskedInput mask="(11) 11111-1111" min={11} />
        </Form.Item>

        <Form.Item className="ant-col-24">
          <Button
            shape="round"
            icon={<CheckOutlined />}
            style={{ width: "100%" }}
            type="primary"
            htmlType="submit"
            loading={loading}
          >
            COMPRAR
          </Button>
        </Form.Item>
      </Form>
      <Popconfirm
        className="no-print"
        title="Quer realmente Sair ?"
        onConfirm={() => signOut()}
        okText="SIM"
        cancelText="NÃO"
      >
        <Button hidden={showTicket} icon={<LogoutOutlined />} shape="round">
          Sair
        </Button>
      </Popconfirm>

      <div
        hidden={!showTicket}
        style={{
          textAlign: "center",
          backgroundColor: "#ffffff70",
          borderRadius: 10,
          paddingTop: 10,
        }}
      >
        <h1 style={{ color: "green" }}>
          <CheckOutlined /> Parabéns
        </h1>
        {ticket && (
          <>
            <h2>{ticket.texto_sucesso || "Compra realizada sucesso"}</h2>
            <Descriptions bordered style={{ width: "100%" }}>
              <Descriptions.Item
                span={3}
                label="Nº do Protocolo"
                style={{ textAlign: "right" }}
              >
                {ticket.protocolo}
              </Descriptions.Item>
              <Descriptions.Item
                span={3}
                label="Data Confirmação"
                style={{ textAlign: "right" }}
              >
                {ticket.dataConfirmacao}
              </Descriptions.Item>
              <Descriptions.Item
                span={3}
                label="Autenticação"
                style={{ textAlign: "right" }}
              >
                {ticket.autenticacao}
              </Descriptions.Item>
              {ticket.cupons.map((cupom, index) => (
                <Descriptions.Item
                  span={3}
                  key={cupom.numero}
                  label={`Número do Cupom ${
                    ticket.cupons.length > 1 ? index + 1 : ""
                  }`}
                  style={{ textAlign: "right" }}
                >
                  <b>
                    {formatNumberTicket(String(cupom.numero).padStart(6, "0"))}
                  </b>
                </Descriptions.Item>
              ))}
            </Descriptions>
          </>
        )}
        <Button
          type="primary"
          className="no-print"
          onClick={handleBack}
          icon={<LeftOutlined />}
          shape="round"
        >
          Voltar
        </Button>
      </div>
    </Container>
  );
};

export default Checkout;
